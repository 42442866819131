

































































































import { AxiosError } from 'axios';
import { mapGetters } from 'vuex';
import { PropType } from 'vue';

import ImageUploader from '@/components/ImageUploader.vue';

import { TStreamer, EStreamerType, EStreamingType } from '@/api/schema';

import * as vr from '@/helpers/validation';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { objectToBlob } from '@/helpers/blobHelper';
import { createStreamer, updateStreamer } from '@/api/CopyStake/Streamers';

const maxFileSize = 1024 * 400;
const imageThreeFourRequirements = `Image aspect ratio must be 3:4 (recommended dimensions is 810 x 1080 pixels) in JPEG, JPG or WEBP (file size – up to 400kb)`;
const imageSixteenNineRequirements = `Image aspect ratio must be 16:9 (recommended dimensions is 1920 x 1080 pixels) in JPEG, JPG or WEBP (file size – up to 400kb)`;

export default {
  name: 'CopyStakeWebStreamerDialog',
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    streamer: {
      type: Object as PropType<TStreamer>,
      default: null
    }
  },
  components: {
    ImageUploader
  },
  data(): any {
    return {
      formData: {
        nickname: null,
        operatorUserId: '',
        accessCode: '',
        type: '',
        language: null
      },
      serverError: {
        nickname: null,
        accessCode: null,
        operatorUserId: null,
        streamingType: null
      },
      uploadImageThreeFourFile: null,
      uploadImageSixteenNineFile: null,
      streamerTypes: [
        { value: EStreamerType.PRIVATE, label: 'Private' },
        { value: EStreamerType.PUBLIC, label: 'Public' }
      ],
      processing: false,
      formValid: false,
      imageThreeFourRequirements,
      imageSixteenNineRequirements,
      maxFileSize
    };
  },
  watch: {
    async value(): Promise<void> {
      await this.resetForm();

      if (this.streamer) {
        this.formData = {
          ...this.streamer
        };
      }
    }
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    },
    rules(): any {
      return {
        nickname: [
          vr.required,
          (v: string) => vr.maxLength(v, 20),
          (v: string) =>
            vr.validByRegExp(
              v,
              /^[A-Za-z0-9\s_.-]{2,20}$/,
              'Only latin letters, number, blank space and symbols ( - _ . ) available'
            )
        ],
        operatorUserId: [vr.required],
        accessCode: [
          (v) => {
            if (this.formData.type === EStreamerType.PRIVATE) {
              return vr.required(v);
            }

            return true;
          },
          (v) => {
            if (!v) return true;

            return !v.includes(' ') || 'No blank space available';
          },
          (v) => {
            if (!v) return true;

            return vr.validByRegExp(
              v,
              /^[0-9A-Z]{6}$/i,
              'Access code field must contains only numbers and A-Z'
            );
          },
          (v) => {
            if (!v) return true;

            return vr.minLength(v, 6);
          },
          (v) => {
            if (!v) return true;

            return vr.maxLength(v, 6);
          }
        ],
        type: [vr.required],
        language: [vr.required],
        imageThreeFour: [
          (v) => vr.fileMaxSize(v, maxFileSize, imageThreeFourRequirements)
        ],
        imageSixteenNine: [
          (v) => vr.fileMaxSize(v, maxFileSize, imageSixteenNineRequirements)
        ]
      };
    },
    isEdit(): boolean {
      return !!this.streamer?.id;
    },
    header(): string {
      return this.isEdit ? 'Edit Streamer' : 'New Streamer';
    },
    submitText(): string {
      return this.isEdit ? 'Edit' : 'Create';
    },
    payload(): any {
      return {
        ...this.formData,
        operatorId: this.operatorId
      };
    },
    imageThreeFourUrl(): string {
      return this.streamer?.images?.THREE_FOUR ?? '';
    },
    imageSixteenNineUrl(): string {
      return this.streamer?.images?.SIXTEEN_NINE ?? '';
    }
  },
  methods: {
    close(clearData: boolean = true): void {
      this.show = false;
      this.$emit('close', clearData);
    },
    async handlerOnSubmit(): Promise<void> {
      try {
        this.resetServerErrors();
        await this.$refs.form.validate();

        if (!this.formValid || this.processing) return;
        this.processing = true;

        const formData = new FormData();

        if (this.uploadImageThreeFourFile) {
          formData.append('imageThreeFour', this.uploadImageThreeFourFile);
        }

        if (this.uploadImageSixteenNineFile) {
          formData.append(
            'imageSixteenNine',
            this.uploadImageSixteenNineFile
          );
        }

        formData.append(
          'streamer',
          objectToBlob({
            ...this.formData,
            accessCode:
              (this.formData.accessCode &&
                this.formData.accessCode.toUpperCase()) ||
              null,
            streamingType: EStreamingType.WEB,
            operatorId: this.operatorId
          })
        );

        let streamer: TStreamer;

        if (this.isEdit) {
          streamer = await updateStreamer(this.streamer.id, formData);
          this.$toast.success(
            `Streamer "${streamer.playerId}" has been successfully updated`
          );
        } else {
          streamer = await createStreamer(formData);
          this.$toast.success(
            `Streamer "${streamer.playerId}" successfully created`
          );
        }

        this.$emit('submit', {
          isEdit: this.isEdit,
          streamer
        });
        this.close(this.isEdit);
      } catch (error) {
        this.parseServerErrors(error);
        errorToastMessage(error);
      } finally {
        this.processing = false;
      }
    },
    handlerOnUploadImageThreeFour(image: File): void {
      this.uploadImageThreeFourFile = image;
    },
    handlerOnUploadImageSixteenNine(image: File): void {
      this.uploadImageSixteenNineFile = image;
    },
    parseServerErrors(error: AxiosError): void {
      if (error instanceof AxiosError) {
        const errorMessage = error.response?.data?.message;
        if (!errorMessage) return;

        if (
          errorMessage.includes('Streamer with access code') ||
          errorMessage.includes('Streamer with accessCode')
        ) {
          this.serverError.accessCode = errorMessage;
        }

        if (errorMessage.includes('Streamer with nickname')) {
          this.serverError.nickname = errorMessage;
        }

        if (errorMessage.includes('Streamer with user')) {
          this.serverError.operatorUserId = errorMessage;
        }

        if (errorMessage.includes('User by operatorUserId')) {
          this.serverError.operatorUserId = errorMessage;
        }

        if (errorMessage.includes('streaming-type')) {
          this.serverError.streamingType = errorMessage;
        }
      }
    },
    resetServerErrors(): void {
      this.serverError = {
        accessCode: null,
        nickname: null,
        operatorUserId: null
      };
    },
    async resetForm(): Promise<void> {
      await this.$refs.form?.reset();
      this.resetServerErrors();

      this.$refs.imageThreeFourUploader?.clearConfiguration();
      this.$refs.imageSixteenNineUploader?.clearConfiguration();
      this.uploadImageThreeFourFile = null;
      this.uploadImageSixteenNineFile = null;

      this.formData = {
        nickname: null,
        operatorUserId: '',
        accessCode: '',
        type: '',
        language: null
      };
    }
  }
};
